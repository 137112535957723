<template>
      
      <div v-if="showDashboard" class="app-admin-wrap sidebar-closed sidebar-compact-onhover sidebar-compact layout-sidebar-vertical sidebar-gradient-purple-indigo">

        <AppSidebar></AppSidebar>

        <div class="switch-overlay"></div>
        <div class="main-content-wrap sidebar-full-z-index mobile-menu-content m-0">

          <AppHeader></AppHeader>

          <AppLoading></AppLoading>

            <div class="main-content mb-5 pb-5">
              
			<div v-if="isDashboard" class="text-initial dashboard-admin pt-5">

				<div class="d-none" style="padding:50px 15px; border:3px solid #f00">
					<Bar :data="data" :options="options" />
				</div>

				<div class="text-center">
					<div v-show="loading" class="loader-bubble loader-bubble-primary"></div>
				</div>

				<div v-if="!loading" class="container-fluid">
					<div class="row align-items-end">

						<div class="d-none">{{ i = 1 }}</div>
						<div v-for="(item, x) in form" :key="item" class="col my-2">
							<div class="card dashboard-card">
								<div class="card-body p-0">
									<div class="px-3 pt-3">
										<strong v-if="x == 'users'">{{ $t("users.text") }}</strong>
										<strong v-if="x == 'activeSubjects'">{{ $t("activeSubjects.text") }}</strong>
										<strong v-if="x == 'inactiveSubjects'">{{ $t("inactiveSubjects.text") }}</strong>
									</div>
									<div class="d-flex align-items-end justify-content-between">
										<div class="p-3">
											<p>{{item}}</p>
											<span>+112.56% <i class="fas fa-chart-line"></i></span>
										</div>
										<div>
											<img class="img-fluid" :src="`${publicPath}assets/images/graph/dashboard-admin-graph-${ i++ }.png`" alt="graph design photo">
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

            <router-view/>
            </div>
          <AppFooter></AppFooter>
        </div>

      </div>


      <AppCustomizer></AppCustomizer>
</template>

<script>
import AppFooter from '@/components/Layout/app-footer'
import AppHeader from '@/components/Layout/app-header.vue'
import AppCustomizer from '@/components/Layout/app-customizer.vue'
import AppSidebar from '@/components/Layout/app-sidebar.vue'
import AppLoading from "@/components/Layout/app-loading";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'HomeView',
  components: {
    AppLoading,
    AppFooter,
    AppHeader,
    AppCustomizer,
    AppSidebar,
	Bar
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      showDashboard:false,
	  loading: 1,
	  form: {},

	  data: {
        labels: ['January', 'February', 'March'],
        datasets: [{ data: [40, 20, 12] }]
      },
      options: {
        responsive: true
      }
    }
  },
  created() {
    this.showDashboard = true;
  },
  methods: {
	getDashboardAdmin() {
        this.$axios.get('dashboard/admin').then(response => {
			this.form = response.data.data;
			console.log('get response: ', this.form);
			this.loading = 0;
        });
    },
  },
  mounted() {
	this.getDashboardAdmin();
  },
  computed: {
    isDashboard() {
        return this.$route.name === 'home'
    }
  },
  beforeCreate() {
  },
  watch: {
  },

}
</script>
