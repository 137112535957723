export default {
  "chgProfileData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Profile Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chgProfileData"])}
  },
  "accountSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountSettings"])}
  },
  "myInvoices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Invoices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myInvoices"])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])}
  },
  "signIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signIn"])}
  },
  "fullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The full name including surname should be entered here."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullName"])}
  },
  "password": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter  password definition to enter in Smart."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])}
  },
  "passwordConfirmation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password Confirmation"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm  password definition to enter in Smart."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwordConfirmation"])}
  },
  "mobileOrEmail": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Or Email"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobileOrEmail"])}
  },
  "countries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])}
  },
  "editCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCountry"])}
  },
  "countryInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryInfo"])}
  },
  "search": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "ID": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "countryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country name."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryName"])}
  },
  "countryCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country code."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryCode"])}
  },
  "countryStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country status."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryStatus"])}
  },
  "operations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operations"])}
  },
  "edit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])}
  },
  "toggleStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toggleStatus"])}
  },
  "noDataFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noDataFound"])}
  },
  "addCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCountry"])}
  },
  "cancel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "return": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])}
  },
  "allRightsReserved": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allRightsReserved"])}
  },
  "terms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
  },
  "requiredMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredMsg"])}
  },
  "minLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Minimum Length Allowed Is 2 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsg"])}
  },
  "maxLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 200 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsg"])}
  },
  "maxLengthMsgTextArea": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 5000 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgTextArea"])}
  },
  "alertLangValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Make Sure That All Tabs Of Language Requirements Are Completed!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertLangValidation"])}
  },
  "minValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Value Is"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minValue"])}
  },
  "minLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Minimum Length Allowed Is 7 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsgMobile"])}
  },
  "maxLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 20 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgMobile"])}
  },
  "numericValidationMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Is For Entering Numbers Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numericValidationMsg"])}
  },
  "emailInvailed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Email Format Is Incorrect"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailInvailed"])}
  },
  "alphaMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Accept English Language Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alphaMsg"])}
  },
  "slugValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Accept English Language , Numbers And Symbols Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slugValidation"])}
  },
  "noOptionsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The List Is Empty"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noOptionsText"])}
  },
  "invalidUsernameOrPass": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Have Entered An Invalid Username Or Password!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidUsernameOrPass"])}
  },
  "addCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCurrency"])}
  },
  "editCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCurrency"])}
  },
  "currencyInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyInfo"])}
  },
  "currencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currencies"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencies"])}
  },
  "currencyName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency name."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyName"])}
  },
  "currencyCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Code"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency code."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyCode"])}
  },
  "currencyStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency status."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyStatus"])}
  },
  "exchangeRateCurrencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Exchange Rate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Exchange Rate"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency exchange rate."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchangeRateCurrencies"])}
  },
  "activeStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeStatus"])}
  },
  "currencySymbol": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Symbol"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Symbol"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency symbol."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencySymbol"])}
  },
  "currencyFormat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Format"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Format"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency format."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyFormat"])}
  },
  "save": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
  },
  "remove": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])}
  },
  "areYouSure": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure ?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])}
  },
  "revertThis": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert This"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revertThis"])}
  },
  "deleteIt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete It"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteIt"])}
  },
  "seen": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seen"])}
  },
  "to": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "from": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])}
  },
  "records": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])}
  },
  "first": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])}
  },
  "last": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last"])}
  },
  "fields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])}
  },
  "fieldsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Fields"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsCreate"])}
  },
  "fieldsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Fields "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsUpdate"])}
  },
  "fieldsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsInfo"])}
  },
  "fieldName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the field name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldName"])}
  },
  "fieldStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the field status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldStatus"])}
  },
  "sectors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectors"])}
  },
  "sectorsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsCreate"])}
  },
  "sectorsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsUpdate"])}
  },
  "sectorsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsInfo"])}
  },
  "sectorsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sector name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsName"])}
  },
  "sectorsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sector status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsStatus"])}
  },
  "samples": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples"])}
  },
  "samplesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesName"])}
  },
  "samplesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesStatus"])}
  },
  "samplesCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples Code"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the samples Code"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCode"])}
  },
  "samplesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesSlug"])}
  },
  "samplesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCreate"])}
  },
  "samplesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesUpdate"])}
  },
  "samplesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesInfo"])}
  },
  "units": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units"])}
  },
  "unitsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsCreate"])}
  },
  "unitsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsUpdate"])}
  },
  "unitsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsInfo"])}
  },
  "unitsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsName"])}
  },
  "unitsSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsSlug"])}
  },
  "unitsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsStatus"])}
  },
  "pleaseWait": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Wait"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseWait"])}
  },
  "active": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])}
  },
  "inactive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])}
  },
  "update": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "basicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicData"])}
  },
  "productSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSettings"])}
  },
  "productInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productInfo"])}
  },
  "modelUnit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models And Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnit"])}
  },
  "paymentSystem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment System"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentSystem"])}
  },
  "packages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])}
  },
  "packagesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesCreate"])}
  },
  "packageUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packageUpdate"])}
  },
  "packagesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesName"])}
  },
  "packagesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesSlug"])}
  },
  "packagesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesStatus"])}
  },
  "packagesUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Units"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package units"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUnits"])}
  },
  "packagesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesInfo"])}
  },
  "packagesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUpdate"])}
  },
  "UltimateSolutions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimate Solutions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UltimateSolutions"])}
  },
  "package": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["package"])}
  },
  "packageName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packageName"])}
  },
  "otherSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otherSettings"])}
  },
  "productName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productName"])}
  },
  "productStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])}
  },
  "domain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domain"])}
  },
  "language": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])}
  },
  "ar": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
  },
  "en": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "clientCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientCode"])}
  },
  "tradeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tradeName"])}
  },
  "productLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Logo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productLogo"])}
  },
  "shoppingCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoppingCountry"])}
  },
  "additionalInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInformation"])}
  },
  "dropFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Files Anywhere To Upload Or"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dropFiles"])}
  },
  "yes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
  },
  "no": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
  },
  "fromModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From a sample"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "chooseUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "MCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question type options MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ"])}
  },
  "exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam"])}
  },
  "HR": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources HR"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])}
  },
  "library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])}
  },
  "forum": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forum"])}
  },
  "chooseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Model"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseModel"])}
  },
  "ZidneyAcademySkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney academy, Zidney skills"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZidneyAcademySkills"])}
  },
  "chooseBasicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Basic Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseBasicData"])}
  },
  "division": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])}
  },
  "seasons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seasons"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasons"])}
  },
  "materials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materials"])}
  },
  "categories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])}
  },
  "other": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
  },
  "productOwnership": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ownership"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOwnership"])}
  },
  "specialZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Zidney"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialZidney"])}
  },
  "specificCustomer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific customer B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "productZidneyPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A product of the Zidney payment system"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "commissionZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney commission for each user"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionZidney"])}
  },
  "limitedContentSet": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited content settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitedContentSet"])}
  },
  "contain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain"])}
  },
  "limited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limited"])}
  },
  "unlimited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])}
  },
  "basicDataName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataName"])}
  },
  "basicDataSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataSlug"])}
  },
  "basicDataStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataStatus"])}
  },
  "basicDataCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataCreate"])}
  },
  "basicDataShowInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic DataShow Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataShowInfo"])}
  },
  "basicDataUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataUpdate"])}
  },
  "lamitedContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamited Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamited Content"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the lamited content"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lamitedContent"])}
  },
  "clientProducts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Client Products"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientProducts"])}
  },
  "products": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])}
  },
  "productsPricesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Products Prices Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesCreate"])}
  },
  "productsPricesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Prices Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesUpdateV"])}
  },
  "productsPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Prices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPrices"])}
  },
  "clientsB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientsB2B"])}
  },
  "partnersB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partnersB2B"])}
  },
  "individualsPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuals Partners"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individualsPartners"])}
  },
  "name": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])}
  },
  "status": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the  status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
  },
  "type": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "target": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])}
  },
  "address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])}
  },
  "create": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])}
  },
  "clientName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientName"])}
  },
  "webSite": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Site"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webSite"])}
  },
  "governmental": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governmental"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])}
  },
  "privateSector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Sector"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privateSector"])}
  },
  "profit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profit"])}
  },
  "nonProfit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Profit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonProfit"])}
  },
  "sector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector"])}
  },
  "areasInterest": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Areas of interest"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areasInterest"])}
  },
  "description": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])}
  },
  "PromoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Codes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodes"])}
  },
  "PromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCode"])}
  },
  "codeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codeName"])}
  },
  "commissionPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionPercentage"])}
  },
  "discountPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discountPercentage"])}
  },
  "activationStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation start"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationStart"])}
  },
  "activationEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation End"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationEnd"])}
  },
  "code": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "branchOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch of"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branchOf"])}
  },
  "PromoCodeType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Code type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodeType"])}
  },
  "general": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])}
  },
  "forCompanies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for companies"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forCompanies"])}
  },
  "forIndividuals": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for individuals"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forIndividuals"])}
  },
  "forSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for subscribers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "B2bClient": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B Client"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "B2bPartner": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B Partner"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "individualPartner": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Partner"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "clientType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "users": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])}
  },
  "activated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])}
  },
  "notActivated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not activated"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notActivated"])}
  },
  "clients": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])}
  },
  "dashboard": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboard"])}
  },
  "usersCount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersCount"])}
  },
  "productPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrice"])}
  },
  "productPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Prices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrices"])}
  },
  "addPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPrice"])}
  },
  "country": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])}
  },
  "currency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])}
  },
  "from500to2000": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 500 to 2000"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from500to2000"])}
  },
  "oneUser": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One User"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneUser"])}
  },
  "oneMonth": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneMonth"])}
  },
  "threeMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["threeMonths"])}
  },
  "sixMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sixMonths"])}
  },
  "twelveMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twelveMonths"])}
  },
  "userNumbers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Numbers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userNumbers"])}
  },
  "home": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
  },
  "organizationalChart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Chart"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizationalChart"])}
  },
  "typesOfWorkTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of work teams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfWorkTeams"])}
  },
  "workTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work teams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeams"])}
  },
  "c": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sections"])}
  },
  "divisions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions"])}
  },
  "subjectsByDivision": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects by division"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjectsByDivision"])}
  },
  "rateCategories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate categories"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rateCategories"])}
  },
  "tags": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])}
  },
  "groups": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])}
  },
  "commissionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionAR"])}
  },
  "addCommission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Commission"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionEN"])}
  },
  "editCommission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Commission"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCommission"])}
  },
  "getStartedTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started with"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedTitle"])}
  },
  "getStartedParagraph": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first item to start building your knowledge base."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedParagraph"])}
  },
  "add": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])}
  },
  "import": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import"])}
  },
  "translation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])}
  },
  "close": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])}
  },
  "default": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])}
  },
  "reset": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])}
  },
  "export": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export"])}
  },
  "manage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage"])}
  },
  "all": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
  },
  "recent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recent"])}
  },
  "archived": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archived"])}
  },
  "filter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])}
  },
  "columns": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columns"])}
  },
  "find": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find"])}
  },
  "typeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeName"])}
  },
  "teamType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teamType"])}
  },
  "number": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])}
  },
  "maxNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of members"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxNumber"])}
  },
  "sectionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionName"])}
  },
  "createSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create Section"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createSection"])}
  },
  "sectionCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionCode"])}
  },
  "sectionType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionType"])}
  },
  "regularSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular section"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regularSection"])}
  },
  "Subsection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub section"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsection"])}
  },
  "DepartmentBranches": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A basic department with branches"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DepartmentBranches"])}
  },
  "DepartmentSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department section"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DepartmentSection"])}
  },
  "groupName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupName"])}
  },
  "translate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["translate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["translate"])}
  },
  "divisionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionName"])}
  },
  "seasonName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["season name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasonName"])}
  },
  "materialName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["material name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materialName"])}
  },
  "materialCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["material Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materialCode"])}
  },
  "mixedMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual Subject"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mixedMaterial"])}
  },
  "categoryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryName"])}
  },
  "category": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category"])}
  },
  "categoryValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Value"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryValue"])}
  },
  "tag": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag"])}
  },
  "managingWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing The Work Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeam"])}
  },
  "managingWorkTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing The Work Team Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamName"])}
  },
  "managingWorkTeamType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing The Work Team Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamType"])}
  },
  "managingWorkTeamNumberOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Managing The Work Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamNumberOf"])}
  },
  "typeOfTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfTeam"])}
  },
  "typeOfWorkTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Team Work Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfWorkTeamName"])}
  },
  "chooseTheUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose  The  Users"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseTheUsers"])}
  },
  "workTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Teams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeam"])}
  },
  "workTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Teams Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamName"])}
  },
  "team": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])}
  },
  "configureSectionsAndGroups": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure Sections And Groups"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configureSectionsAndGroups"])}
  },
  "groupsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupsName"])}
  },
  "sectionsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionsName"])}
  },
  "userMangment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Mangment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userMangment"])}
  },
  "permissionManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissionManagement"])}
  },
  "usersPermissionManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Permission Management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersPermissionManagement"])}
  },
  "profileName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profileName"])}
  },
  "permissionProfile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Profile"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissionProfile"])}
  },
  "FullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The full name including surname should be entered here."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FullName"])}
  },
  "Birthday": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user's dates of birth should be entered into this field."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])}
  },
  "mobileNo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile No"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile No"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter mobile No."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobileNo"])}
  },
  "Photo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on the Choose File button, the system browses the folders to upload  photo."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])}
  },
  "Address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the address."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
  },
  "Male": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])}
  },
  "Female": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
  },
  "phoneNo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter  phone No"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phoneNo"])}
  },
  "surName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surName"])}
  },
  "categoryAndMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category And Material"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryAndMaterial"])}
  },
  "permissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])}
  },
  "subjects": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjects"])}
  },
  "file": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])}
  },
  "upload": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])}
  },
  "dragDropText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please drag & drop a file from your computer or click to browse."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dragDropText"])}
  },
  "affiliates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["affiliates"])}
  },
  "promoType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promo type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoType"])}
  },
  "startDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startDate"])}
  },
  "endDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endDate"])}
  },
  "relatedTo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["related to"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endDate"])}
  },
  "expired": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])}
  },
  "generateCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generate code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generateCode"])}
  },
  "generatePromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generate promo code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generatePromoCode"])}
  },
  "charset": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["charset"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["charset"])}
  },
  "example": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example"])}
  },
  "pattern": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pattern"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pattern"])}
  },
  "questionsBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsBasket"])}
  },
  "MCQsettingsModels": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ settings and models"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQsettingsModels"])}
  },
  "originalSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["originalSettings"])}
  },
  "createForms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create forms"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createForms"])}
  },
  "formManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formManagement"])}
  },
  "requiredFields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required fields"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredFields"])}
  },
  "chooseMaterials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose materials"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseMaterials"])}
  },
  "chooseDivisions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the divisions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseDivisions"])}
  },
  "questionText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question text"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionText"])}
  },
  "photo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo"])}
  },
  "correctAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The correct answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctAnswer"])}
  },
  "oneChoice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one choice"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneChoice"])}
  },
  "explainAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain the answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explainAnswer"])}
  },
  "createModelMCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create Model MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createModelMCQ"])}
  },
  "updateModelMCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update Model MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updateModelMCQ"])}
  },
  "modelName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelName"])}
  },
  "questionType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionType"])}
  },
  "normal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal"])}
  },
  "multiOption": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one option is correct"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiOption"])}
  },
  "questionOrdinal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the question is ordinal"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionOrdinal"])}
  },
  "containsImage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the question contains an image"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containsImage"])}
  },
  "containsInteractive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the question contains interactive content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containsInteractive"])}
  },
  "chooseValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a value"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseValue"])}
  },
  "activeForm": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active the form"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeForm"])}
  },
  "nameForm": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name Form"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nameForm"])}
  },
  "UQOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit questions options"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UQOptions"])}
  },
  "createQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQuestion"])}
  },
  "EditQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EditQuestion"])}
  },
  "createQuestionsBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Questions Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQuestionsBasket"])}
  },
  "fillingBaskets": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filling Baskets"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fillingBaskets"])}
  },
  "basket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basket"])}
  },
  "baskets": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baskets"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baskets"])}
  },
  "basketName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basketName"])}
  },
  "basketCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "maxNumForBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum limit of the questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxNumForBasket"])}
  },
  "numberOfQuestionsCurrently": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of questions currently"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfQuestionsCurrently"])}
  },
  "branches": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])}
  },
  "generalBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalBasket"])}
  },
  "relatedBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relatedBasket"])}
  },
  "links": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["links"])}
  },
  "completed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])}
  },
  "pindding": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindding"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pindding"])}
  },
  "reviewed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewed"])}
  },
  "authenticated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticated"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticated"])}
  },
  "audited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audited "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audited"])}
  },
  "availableExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available For Exams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["availableExams"])}
  },
  "availableReview": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliable For Review"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["availableReview"])}
  },
  "confirmed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])}
  },
  "questionNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Number"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionNumber"])}
  },
  "questionNote": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Note"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionNote"])}
  },
  "lastEditDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Edit Date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lastEditDate"])}
  },
  "subject": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subject"])}
  },
  "show": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])}
  },
  "chooseQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseQuestion"])}
  },
  "chooseTheBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose The Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseTheBasket"])}
  },
  "cancelQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancle Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelQuestion"])}
  },
  "MCQExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ Exams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQExams"])}
  },
  "examData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examData"])}
  },
  "examSetting": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Setting"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examSetting"])}
  },
  "theExaminersInterface": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Examiners Interface"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theExaminersInterface"])}
  },
  "downloadQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadQuestions"])}
  },
  "examStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examStatus"])}
  },
  "directedExamFor": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directed Exam For"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directedExamFor"])}
  },
  "subscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribers"])}
  },
  "visitors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visitors"])}
  },
  "fromSample": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Sample"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromSample"])}
  },
  "numberOfQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfQuestions"])}
  },
  "examName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examName"])}
  },
  "examCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examCode"])}
  },
  "examDurationWithMinuts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Duration With Minuts"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examDurationWithMinuts"])}
  },
  "createUrl": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Url"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createUrl"])}
  },
  "examType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examType"])}
  },
  "longTimeExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long Time Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longTimeExam"])}
  },
  "programmedExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmed Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programmedExam"])}
  },
  "successExamReult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Mark To pass"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successExamReult"])}
  },
  "examStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Start"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examStart"])}
  },
  "examEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam End"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examEnd"])}
  },
  "orderOfOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Of Options"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orderOfOptions"])}
  },
  "uniformArrangement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniform Arrangement"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformArrangement"])}
  },
  "differentOrder": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different Order"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["differentOrder"])}
  },
  "examResult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examResult"])}
  },
  "nature": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nature"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nature"])}
  },
  "areaOfFocus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area of Focus"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areaOfFocus"])}
  },
  "client": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])}
  },
  "quickInterface": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick interface"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quickInterface"])}
  },
  "passOnce": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pass Once"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passOnce"])}
  },
  "options": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])}
  },
  "examActivationAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Activation Alert"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examActivationAlert"])}
  },
  "warningThatTheEndOfTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning That The End Of TheExam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warningThatTheEndOfTheExam"])}
  },
  "day": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])}
  },
  "before": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before"])}
  },
  "messagesOfResults": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages of results, congratulations and encouragement"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messagesOfResults"])}
  },
  "generalResult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Result"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalResult"])}
  },
  "correctionAfterCompletingTheQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction  After Completing The Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionAfterCompletingTheQuestion"])}
  },
  "detailedResultsAndStatistics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Results And Statistics"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detailedResultsAndStatistics"])}
  },
  "verticalView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical View"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verticalView"])}
  },
  "horizontalView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal View"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horizontalView"])}
  },
  "motivationalMessages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivational Messages"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivationalMessages"])}
  },
  "addMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Message"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addMessage"])}
  },
  "questionOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question and options"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionOptions"])}
  },
  "note": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])}
  },
  "appearsProfessors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A section that appears for professors only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appearsProfessors"])}
  },
  "classificationQsettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification and question settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classificationQsettings"])}
  },
  "statistics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistics"])}
  },
  "manual": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])}
  },
  "automatic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatic"])}
  },
  "added": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added"])}
  },
  "remaining": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining"])}
  },
  "questionsList": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions List"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsList"])}
  },
  "examQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examQuestions"])}
  },
  "addExamQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exam Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addExamQuestions"])}
  },
  "examsUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examsUnits"])}
  },
  "models": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["models"])}
  },
  "model": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])}
  },
  "modelSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelSlug"])}
  },
  "totalModelPoints": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Model Points"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totalModelPoints"])}
  },
  "startWithText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start With Text"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startWithText"])}
  },
  "content": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])}
  },
  "addPart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Part"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPart"])}
  },
  "addSubpart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subpart"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addSubpart"])}
  },
  "partHaveContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The section contains a data statement"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partHaveContent"])}
  },
  "nameOfPart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Of Part"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nameOfPart"])}
  },
  "managePricesPackages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage prices and packages"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ManagePricesPackages"])}
  },
  "dollar": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dollar"])}
  },
  "monthlyprice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthlyprice"])}
  },
  "3months": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3months"])}
  },
  "6months": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6months"])}
  },
  "12months": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12months"])}
  },
  "poll": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll"])}
  },
  "durationTrial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration of trial"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durationTrial"])}
  },
  "topic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topic"])}
  },
  "topicName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicName"])}
  },
  "topicSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicSlug"])}
  },
  "promoCodesAndPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Codes And Partners"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodesAndPartners"])}
  },
  "partners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partners"])}
  },
  "promoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Codes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodes"])}
  },
  "licensing": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licensing"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licensing"])}
  },
  "notSubscribed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Subscribed"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notSubscribed"])}
  },
  "typeOfSubscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Subscriber"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfSubscriber"])}
  },
  "platformSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformSettings"])}
  },
  "modelUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model and units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnits"])}
  },
  "InterfaceManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InterfaceManagement"])}
  },
  "licenseSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseSettings"])}
  },
  "licensingInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licensing information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licensingInformation"])}
  },
  "licenseEndAlerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License end alerts"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndAlerts"])}
  },
  "licenseRenewalApplication": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License renewal application"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseRenewalApplication"])}
  },
  "productType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productType"])}
  },
  "licenseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseDuration"])}
  },
  "licenseNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license number"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseNumber"])}
  },
  "licenseStartDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License start date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseStartDate"])}
  },
  "licenseEndDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License end date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndDate"])}
  },
  "platformName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformName"])}
  },
  "platformLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform logo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformLogo"])}
  },
  "defaultDisplayLanguage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default display language"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultDisplayLanguage"])}
  },
  "packagesPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" packages Prices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesPrices"])}
  },
  "remainingLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining until the end of the license"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remainingLicense"])}
  },
  "notificationBeforeEndLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending a notification of the end of the license before the end of the license"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notificationBeforeEndLicense"])}
  },
  "frequencyAlerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency of alerts every"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultDisplayLanguage"])}
  },
  "departments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departments"])}
  },
  "department": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["department"])}
  },
  "noParentDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Parent Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noParentDepartment"])}
  },
  "departmentType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentType"])}
  },
  "normalDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normalDepartment"])}
  },
  "subDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subDepartment"])}
  },
  "baseWithBranchesDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base With Branches Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baseWithBranchesDepartment"])}
  },
  "divisionOfDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Of Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionOfDepartment"])}
  },
  "departmentCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentCode"])}
  },
  "link_basket_questions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Question with The Basket"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link_basket_questions"])}
  },
  "next": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])}
  },
  "backOfficeTrialVersion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Office Trial Version"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backOfficeTrialVersion"])}
  },
  "putMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put Mark"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["putMark"])}
  },
  "questionsTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions Templates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsTemplates"])}
  },
  "chooseMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose material"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseMaterial"])}
  },
  "main": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main"])}
  },
  "topicCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic Code "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicCode"])}
  },
  "modelHeader": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Header "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelHeader"])}
  },
  "modelFooter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Model Footer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelFooter"])}
  },
  "direct": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direct"])}
  },
  "choice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choice"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choice"])}
  },
  "descriptionAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descriptionAnswer"])}
  },
  "checkMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Mark"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkMark"])}
  },
  "questionContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionContent"])}
  },
  "notes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])}
  },
  "mark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mark"])}
  },
  "enableExport": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Export"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enableExport"])}
  },
  "Exercises": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise"])}
  },
  "exerciseTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseTemplate"])}
  },
  "exerciseUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseUnits"])}
  },
  "exerciseName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseName"])}
  },
  "exerciseSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseSlug"])}
  },
  "organizationalChartName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Chart Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizationalChartName"])}
  },
  "teamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Team Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teamName"])}
  },
  "departmentName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentName"])}
  },
  "subjectName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjectName"])}
  },
  "tagName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tagName"])}
  },
  "statusChanged": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The status has been changed successfully."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statusChanged"])}
  },
  "chooseThesubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose The Subscribers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseThesubscribers"])}
  },
  "transferToAnotherDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer To Another Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferToAnotherDepartment"])}
  },
  "youShouldChooseDifferentDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Should Choose Different  Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youShouldChooseDifferentDepartment"])}
  },
  "exams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exams"])}
  },
  "roleName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleName"])}
  },
  "transferToAnotherTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer To Another Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferToAnotherTeam"])}
  },
  "youShouldChooseDifferentTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Should Choose Different Team"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youShouldChooseDifferentTeam"])}
  },
  "pointsAndRates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points and Rates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pointsAndRates"])}
  },
  "total": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])}
  },
  "fullMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full mark"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullMark"])}
  },
  "generalRate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general Rate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalRate"])}
  },
  "dotting": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dotting"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dotting"])}
  },
  "factor": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factor"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factor"])}
  },
  "dottingPattern": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dotting pattern"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dottingPattern"])}
  },
  "percentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])}
  },
  "salesAndSubscriptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales and subscriptions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salesAndSubscriptions"])}
  },
  "sales": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales"])}
  },
  "subscriptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriptions"])}
  },
  "dateRegistration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date of registration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateRegistration"])}
  },
  "activation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation"])}
  },
  "activationDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationDate"])}
  },
  "userRegistration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data upon registration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userRegistration"])}
  },
  "freeSubscription": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free subscription"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["freeSubscription"])}
  },
  "userName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userName"])}
  },
  "packagePrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagePrice"])}
  },
  "paymentPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentPrice"])}
  },
  "identificationNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification Number ID"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificationNumber"])}
  },
  "subscriberActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberActivation"])}
  },
  "subscriberOrganization": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber organization"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberOrganization"])}
  },
  "marked": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marked"])}
  },
  "excelFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Excel File"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excelFile"])}
  },
  "downloadSimple": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Simple"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadSimple"])}
  },
  "fileIsReq": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file is required."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fileIsReq"])}
  },
  "price": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])}
  },
  "activationCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationCode"])}
  },
  "oneSubscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One subscriber"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneSubscriber"])}
  },
  "subscriberGroup": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber group"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberGroup"])}
  },
  "choose": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose"])}
  },
  "subscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriber"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriber"])}
  },
  "deservedAmount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deserved amount"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deservedAmount"])}
  },
  "paymentMethod": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentMethod"])}
  },
  "electronic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["electronic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["electronic"])}
  },
  "namePayer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of the payer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["namePayer"])}
  },
  "confirmPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm Payment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmPayment"])}
  },
  "cancelPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel Payment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelPayment"])}
  },
  "paymentNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment number"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentNumber"])}
  },
  "automaticActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automaticActivation"])}
  },
  "donePayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done Payment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donePayment"])}
  },
  "defaultLanguage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Language"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultLanguage"])}
  },
  "grade": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
  },
  "percent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percent"])}
  },
  "factory": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factory"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factory"])}
  },
  "mainClassification": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Classification"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mainClassification"])}
  },
  "notVisibleToSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Visible To Subscribers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notVisibleToSubscribers"])}
  },
  "pending": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
  },
  "messageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Templates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messageTemplates"])}
  },
  "createMessageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Message Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createMessageTemplates"])}
  },
  "editMessageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Message Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editMessageTemplates"])}
  },
  "nameMessageTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Message Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nameMessageTemplate"])}
  },
  "trash": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trash"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trash"])}
  },
  "searchErrorMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least three letters"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["searchErrorMsg"])}
  },
  "fullExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullExam"])}
  },
  "mcqExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqExam"])}
  },
  "exercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise"])}
  },
  "exerciseCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseCode"])}
  },
  "failMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail Message"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failMessage"])}
  },
  "successMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Message"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successMessage"])}
  },
  "motavMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivaion Message"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motavMessage"])}
  },
  "libraries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libraries"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["libraries"])}
  },
  "typesOfFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TypesOfFiles"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfFiles"])}
  },
  "extensionsOfFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExtensionsOfFiles"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extensionsOfFiles"])}
  },
  "files": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])}
  },
  "mcq_exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcq_exam"])}
  },
  "topics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topics"])}
  },
  "INFO": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO"])}
  },
  "customActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom Activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customActivation"])}
  },
  "answers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answers"])}
  },
  "addAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addAnswer"])}
  },
  "addNote": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Note"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addNote"])}
  },
  "history": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["history"])}
  },
  "data": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])}
  },
  "innerSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inner Section"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerSection"])}
  },
  "mcqHistory": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edits log"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqHistory"])}
  },
  "showQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["showQuestion"])}
  },
  "errorFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Found"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errorFound"])}
  },
  "and": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
  },
  "specialContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialContent"])}
  },
  "extensionsOfFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions Of File"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extensionsOfFile"])}
  },
  "typesOfFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of File"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfFile"])}
  },
  "open": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open"])}
  },
  "lessons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessons"])}
  },
  "lessonName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessonName"])}
  },
  "contain_certificat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain Certificat"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain_certificat"])}
  },
  "certificates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificates"])}
  },
  "lesson": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesson"])}
  },
  "certificateName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificateName"])}
  },
  "uploadBackgroundImage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Background  Image"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadBackgroundImage"])}
  },
  "certificate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificate"])}
  },
  "selectMsgTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Message Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectMsgTemplate"])}
  },
  "time": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])}
  },
  "date": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])}
  },
  "startTime": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startTime"])}
  },
  "answerCorrectOrdering": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب التصحيح "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answerCorrectOrdering"])}
  },
  "template": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])}
  },
  "complateCondition": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion Conditions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complateCondition"])}
  },
  "answerOrdering": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Ordering"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answerOrdering"])}
  },
  "theAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theAnswer"])}
  },
  "infoAboutQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infoAboutQuestion"])}
  },
  "degreeOfQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DegreeOfQuestion"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degreeOfQuestion"])}
  },
  "tracks": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracks"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tracks"])}
  },
  "track": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["track"])}
  },
  "instructions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instructions"])}
  },
  "instruction": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruction"])}
  },
  "review": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])}
  },
  "evaluation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluation"])}
  },
  "solveTopics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solve Topics"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solveTopics"])}
  },
  "ads": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ads"])}
  },
  "systemReports": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Reports"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["systemReports"])}
  },
  "userReports": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Reports"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userReports"])}
  },
  "reportsTypes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports Types"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportsTypes"])}
  },
  "question": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])}
  },
  "feedbacks": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedbacks"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbacks"])}
  },
  "feedbackTypes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Types"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbackTypes"])}
  },
  "feedbackUserExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback User Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbackUserExam"])}
  },
  "feedbacZideny": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Zideny"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbacZideny"])}
  },
  "comments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])}
  },
  "screenshot": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshot"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["screenshot"])}
  },
  "lives": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lives"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lives"])}
  },
  "live": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["live"])}
  },
  "duration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])}
  },
  "link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link"])}
  },
  "student": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student"])}
  },
  "assessments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assessments"])}
  },
  "scheduledExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Exams"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduledExams"])}
  },
  "suggestedDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suggestedDuration"])}
  },
  "beforeYouStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beforeYouStart"])}
  },
  "oneTimeOttempt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time ottempt."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneTimeOttempt"])}
  },
  "reviewingAnswerDuringTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewing answer during the exam."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewingAnswerDuringTheExam"])}
  },
  "shareableCertificateOfCompletion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareable certificate of completion."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shareableCertificateOfCompletion"])}
  },
  "examinerMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examiner mode."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examinerMode"])}
  },
  "relaxMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax mode."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relaxMode"])}
  },
  "chronoMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrono mode."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chronoMode"])}
  },
  "start": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])}
  },
  "minutes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])}
  },
  "continue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continue"])}
  },
  "startingIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting in ..."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startingIn"])}
  },
  "relax": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relax"])}
  },
  "answer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answer"])}
  },
  "hint": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hint"])}
  },
  "skip": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skip"])}
  },
  "finish": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finish"])}
  },
  "unansweredQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unansweredQuestions"])}
  },
  "favoritQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favoriteQuestions"])}
  },
  "anythingElseYouLikeAdd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anything else you'd like to add?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anythingElseYouLikeAdd"])}
  },
  "howWouldYouRateYourExperience": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate your your experience?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["howWouldYouRateYourExperience"])}
  },
  "doYouFeelLikeTestSccuratelyMeasuredYourSkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you feel like test accurately measure your skills?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doYouFeelLikeTestSccuratelyMeasuredYourSkills"])}
  },
  "fantastic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fantastic"])}
  },
  "tryAgain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tryAgain"])}
  },
  "fantasticMesg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great job on getting it right! Keep up the momentum!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fantasticMesg"])}
  },
  "tryAgainMesg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep going! Mistakes happen, but so does learning."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tryAgainMesg"])}
  },
  "noUnansweredMarkedOrFavoriteFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Skipped Or Marked Questions Found."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noUnansweredMarkedOrFavoriteFound"])}
  },
  "learningRecommendations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning recommendations."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningRecommendations"])}
  },
  "learningRecommendationsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These personalized recommendations are based on your skill gaps. Start one now to improve your skills."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningRecommendationsText"])}
  },
  "summary": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])}
  },
  "CompletingExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have finished the exam."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CompletingExam"])}
  },
  "HoldItRightThereDes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have answered and marked questions. you can review them before completing the exam."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoldItRightThereDes"])}
  },
  "HoldItRightThere": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoldIt Right There!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoldItRightThere"])}
  },
  "youScoredBetterThan": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Scored Better Than"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youScoredBetterThan"])}
  },
  "ofAllExamTakers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of All  Exam Takers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ofAllExamTakers"])}
  },
  "unansweredAndMarkedQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered And Marked Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unansweredAndMarkedQuestions"])}
  },
  "CongratulationsPRag": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, you've exceeded the required average."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CongratulationsPRag"])}
  },
  "mcq": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcq"])}
  },
  "testYourself": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Yourself"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testYourself"])}
  },
  "discourse": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discourse"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discourse"])}
  },
  "rushMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rush Mode"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rushMode"])}
  },
  "zidneyExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zidneyExams"])}
  },
  "correctionMethod": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction Method"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionMethod"])}
  },
  "AI": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])}
  },
  "Manually": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually"])}
  },
  "score": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])}
  },
  "fail": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fail"])}
  },
  "youNotAnswerThisQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Not Answer These Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youNotAnswerThisQuestion"])}
  },
  "viewUnansweredQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Unanswered Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewUnansweredQuestions"])}
  },
  "books": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["books"])}
  },
  "previousView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous View"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previousView"])}
  },
  "Library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])}
  },
  "discover": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover"])}
  },
  "noContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noContent"])}
  },
  "learningPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Path"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningPath"])}
  },
  "greatToSeeYouAgain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great To See You Again!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["greatToSeeYouAgain"])}
  },
  "resume": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resume"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resume"])}
  },
  "discoverPaths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Paths"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discoverPaths"])}
  },
  "yourPaths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Paths"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yourPaths"])}
  },
  "path": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Path"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["path"])}
  },
  "name_": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name_"])}
  },
  "createYourPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Your Path"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createYourPath"])}
  },
  "assign": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assign"])}
  },
  "yourPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Path"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yourPath"])}
  },
  "username": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "selectAll": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectAll"])}
  },
  "institutionalData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutiona lData"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["institutionalData"])}
  },
  "mailAndNotifications": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail And Notifications"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailAndNotifications"])}
  },
  "shareContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shareContent"])}
  },
  "report": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report"])}
  },
  "usersManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersManagement"])}
  },
  "leaveFeedback": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leave feedback"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leaveFeedback"])}
  },
  "permission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permission"])}
  },
  "user": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])}
  },
  "questions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questions"])}
  },
  "createdDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createdDate"])}
  },
  "management": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["management"])}
  },
  "usersPermissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Permissions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersPermissions"])}
  },
  "addUsersToDepartments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Users To Department"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addUsersToDepartments"])}
  },
  "createdBy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createdBy"])}
  },
  "addPermissionData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Permission Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPermissionData"])}
  },
  "true": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])}
  },
  "false": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])}
  },
  "typeOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOf"])}
  },
  "checkStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkStatus"])}
  },
  "activeRevice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Revice"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeRevice"])}
  },
  "activeExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeExam"])}
  },
  "mcqStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqStatus"])}
  },
  "pleaseChooseTopicModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kindly choose a topic model."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseChooseTopicModel"])}
  },
  "pleaseChooseEcerciseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kindly choose an exercise model."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseChooseEcerciseModel"])}
  },
  "getStartedTopic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get started with creating your topic, take the first step by selecting a model."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedTopic"])}
  },
  "getStartedExercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get started with creating your exercise, take the first step by selecting a model."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedExercise"])}
  },
  "containCertificate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain Certificate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containCertificate"])}
  },
  "Exercisestatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercisestatus"])}
  },
  "success": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])}
  },
  "topicStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topic Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicStatus"])}
  },
  "correctionAfterCompletingTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correction After Completing The Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionAfterCompletingTheExam"])}
  },
  "duplicate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duplicate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duplicate"])}
  },
  "timingAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing Alert"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timingAlert"])}
  },
  "thisExamWillEndIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Exam Will End In : "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thisExamWillEndIn"])}
  },
  "days": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])}
  },
  "TheExercises": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Exercises"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TheExercises"])}
  },
  "Exercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise"])}
  },
  "activeExercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Exercise"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeExercise"])}
  },
  "ExerciseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExerciseDuration"])}
  },
  "ExerciseStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExerciseStatus"])}
  },
  "finishTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish The Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finishTheExam"])}
  },
  "waitAssessment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while we prepare your assessment!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waitAssessment"])}
  },
  "AssessmentShortly": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your assessment will begin shortly"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AssessmentShortly"])}
  },
  "goodLuck": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Luck!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goodLuck"])}
  },
  "errorPageMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! The Page You Were Looking For Doesn't Exist."])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errorPageMsg"])}
  },
  "goBackToHome": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back To Home"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goBackToHome"])}
  },
  "mcqAssessment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ - Assessment"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqAssessment"])}
  },
  "noRushJustFocus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no rush, just Focus!"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noRushJustFocus"])}
  },
  "flaggedQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flagged Question"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flaggedQuestion"])}
  },
  "settings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
  },
  "submitAnswers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitAnswers"])}
  },
  "pleaseEnterTheStandardsOfYourExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter The Standards Of Your Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseEnterTheStandardsOfYourExam"])}
  },
  "downloadWord": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a Word document. Click below to download:"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadWord"])}
  },
  "download": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Document"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])}
  },
  "correctionOfAnswers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction Of Answers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionOfAnswers"])}
  },
  "correctOrder": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct Order:"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctOrder"])}
  },
  "waitingForResults": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting For Results"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waitingForResults"])}
  },
  "Loading": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])}
  },
  "checkTheAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discribe The Answer"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkTheAnswer"])}
  },
  "nextQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nextQuestion"])}
  },
  "finishTheQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish The Question"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finishTheQuestion"])}
  },
  "issuedOn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued On"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issuedOn"])}
  },
  "timeOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeOfExam"])}
  },
  "QuestionsOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QuestionsOfExam"])}
  },
  "ScoreOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ScoreOfExam"])}
  },
  "pleaseMoveTheAnswersToReorderThem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please move the answers to reorder them."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseMoveTheAnswersToReorderThem"])}
  },
  "youMustEnterQuestionCount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Must Enter Question Count."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youMustEnterQuestionCount"])}
  },
  "examinerType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examiner Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examinerType"])}
  },
  "scheduled": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled"])}
  },
  "myCertifications": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my Certifications"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myCertifications"])}
  },
  "view": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])}
  },
  "areYouSureLeave": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you Sure you want to leave?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSureLeave"])}
  },
  "youWillLeave?": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful, you will end the exam if you choose to leave."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youWillLeave"])}
  },
  "exitAssesment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Assesment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exitAssesment"])}
  },
  "keepGoing": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Going"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keepGoing"])}
  },
  "result": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result"])}
  },
  "questionsCount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions Count"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsCount"])}
  },
  "seeMore": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seeMore"])}
  },
  "activeSubjects": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Subjects"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeSubjects"])}
  },
  "inactiveSubjects": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Subjects"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactiveSubjects"])}
  },
  "certificatesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificatesInfo"])}
  },
  "pleaseEnterYourFeedback": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Feedback"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseEnterYourFeedback"])}
  }
}