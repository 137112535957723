<template>
      
      <div v-if="showDashboard" class="app-admin-wrap sidebar-closed sidebar-compact-onhover sidebar-compact layout-sidebar-vertical sidebar-gradient-purple-indigo">
        <AppSidebar></AppSidebar>

        <div class="switch-overlay"></div>
        <div class="main-content-wrap sidebar-full-z-index mobile-menu-content m-0">

          <AppHeader></AppHeader>

          <AppLoading></AppLoading>

            <div class="main-content">
                <router-view/>
            </div>
          <AppFooter></AppFooter>
        </div>

      </div>

      <AppCustomizer></AppCustomizer>
</template>

<script>
import AppFooter from '@/components/Students/Layout/app-footer'
import AppHeader from '@/components/Students/Layout/app-header.vue'
import AppCustomizer from '@/components/Students/Layout/app-customizer.vue'
import AppSidebar from '@/components/Students/Layout/app-sidebar.vue'
import AppLoading from "@/components/Students/Layout/app-loading";

export default {
  name: 'StudentView',
  components: {
    AppLoading,
    AppFooter,
    AppHeader,
    AppCustomizer,
    AppSidebar
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      showDashboard:false,
     
    }
  },
  created() {
    this.showDashboard = true;
  },
  methods: {
   
  },
  mounted() {

  },
  computed: {
  },
  beforeCreate() {
  },
  watch: {
  },

}
</script>
